<template>
	<div class="formulaire__ficheparam">
		<svg class="logo" fill="#68ABAF" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 100 125" enable-background="new 0 0 100 100" xml:space="preserve"><path d="M10.107,62.461c-0.704,0-1.407-0.269-1.944-0.806c-1.074-1.073-1.074-2.814,0-3.889l14.033-14.034  c1.074-1.074,2.814-1.074,3.889,0c1.074,1.073,1.074,2.814,0,3.889L12.052,61.655C11.515,62.192,10.812,62.461,10.107,62.461z"/><path d="M91.837,8.163c-0.757-0.758-1.886-1.004-2.886-0.64L20.049,32.629c-1.087,0.396-1.81,1.43-1.809,2.586  s0.725,2.188,1.812,2.583l30.927,11.223l11.224,30.928c0.395,1.087,1.427,1.811,2.583,1.812c0.001,0,0.001,0,0.002,0  c1.155,0,2.188-0.723,2.584-1.809l25.105-68.902C92.843,10.044,92.593,8.919,91.837,8.163z M64.792,70.963L55.71,45.936  c-0.277-0.766-0.881-1.369-1.646-1.646l-25.026-9.081l56.251-20.496L64.792,70.963z M52.378,73.915L38.345,87.948  c-1.074,1.074-1.074,2.814,0,3.889c0.537,0.537,1.24,0.806,1.944,0.806s1.407-0.269,1.944-0.806l14.033-14.033  c1.074-1.074,1.074-2.814,0-3.889S53.452,72.841,52.378,73.915z M45.79,54.21c-1.074-1.074-2.814-1.074-3.889,0L27.868,68.244  c-1.074,1.074-1.074,2.815,0,3.889c0.537,0.537,1.24,0.806,1.944,0.806s1.407-0.269,1.944-0.806L45.79,58.099  C46.864,57.024,46.864,55.283,45.79,54.21z"/></svg>
		<h3 class="mt-10">Générez la fiche de paramétrage</h3>
		<v-form ref="form">
			<InputSearchCab 
				@change="cab_change"
				class="mt-8"
				require
			>
			</InputSearchCab>
			<v-btn 
				class="mt-0" 
				depressed 
				color="#68ABAF" 
				:dark="cab != ''"
				:disabled="cab == ''"
				:href="makeUrl()"
				target="_blank"
			>
				Générer
			</v-btn>
			<v-btn 
				class="ml-4" 
				depressed 
				@click="$router.go(-1);"
			>
				Retour
			</v-btn>		
		</v-form>
	</div>
</template>

<script>
	import InputSearchCab from '../InputCabSearch.vue'
	import { mapActions, mapMutations } from 'vuex';
	import {api} from '../../api.js';

	export default {
		name: 'Ficheparam',
		components : {
			InputSearchCab
		},
		data: () => ({
			cab: "123456"
		}),
		methods: {
			...mapActions('formulaires', [
				"sendEnvoieMasse",
				"getEnvoieMasseListMotif"
			]),
			...mapMutations('global', [
				"sendSuccessNotif"
			]),
			cab_change: function(e) {
				this.cab = e;
			},
			makeUrl: function() {
				return api.formulaires.getFicheParam() + this.cab + "?token=" + localStorage.getItem("intranetToken")
			}
		},
	};
</script>

<style lang="scss">
	.formulaire__ficheparam {
		width: 40%;
		border: 4px solid #f1f1f1;
		border-radius: 16px;
		margin-top: 30px;
		padding: 20px;
		margin-left: auto;
		margin-right: auto;
		position: relative;
		overflow: display;
		padding-bottom: 30px;

		.logo
		{
			width: 50px;
			position: absolute;
			margin-left: auto;
			margin-right: auto;
			top: -25px;
			left:0;
			right: 0;
			z-index: 1000;
		}
	}

	@media only screen and (min-width: 701px) and (max-width: 800px) {
		.formulaire__ficheparam
		{
			width: 50%;
		}
	}

	@media only screen and (min-width: 420px) and (max-width: 700px) {
		.formulaire__ficheparam
		{
			width: 80%;
		}
	}

	@media only screen and (min-width: 0px) and (max-width: 419px) {
		.formulaire__ficheparam
		{
			width: 90%;
		}
	}
</style>